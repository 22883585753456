import p1 from "../assests/Group-1273.jpg";
import p2 from "../assests/Group-1272.jpg";
import p3 from "../assests/Group-1274.jpg";
import p4 from "../assests/Group-1275-935x701.jpg";
import p5 from "../assests/Group-1269-935x701.jpg";
import p6 from "../assests/Group-1268-935x701.jpg";
import p7 from "../assests/Group-1270-935x701.jpg";
import p8 from "../assests/Group-1271.jpg";

const ProductsData = [
    {
        image:p1,
        name:"beast",
        price:"$530",
        cart:"Add to cart"
    },
    {
        image:p2,
        name:"yearphone",
        price:"$320",
        cart:"add to cart"
    },
    {
        image:p3,
        name:"head",
        price:"$240",
        price2:"",
        cart:"add to cart",
    },
    {
        image:p4,
        name:"button",
        price:"$120",
        price2:"$350",
        cart:"add to cart",
        sale:"sale!",
        sale2:"sale-btn",
        span:"span"
    },
    {
        image:p5,
        name:"beast",
        price:"$530",
        cart:"Add to cart"
    },
    {
        image:p6,
        name:"yearphone",
        price:"$320",
        cart:"add to cart"
    },
    {
        image:p7,
        name:"head",
        price:"$240",
        price2:"",
        cart:"add to cart",
    },
    {
        image:p8,
        name:"button",
        price:"$120",
        price2:"$350",
        cart:"add to cart",
        sale:"sale!",
        sale2:"sale-btn",
        span:"span"
    }

    
];
export default ProductsData;